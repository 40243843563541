const token = '9108b81b421ff3035a3cb817d9f0d0b91d9a32f9e57387ac484bd172deb71bcb';
import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Max-Age": 3600,
    "Access-Control-Allow-Credentials": true,
    "Authorization": `${token}`
  }
})

