"use strict"
import http from "../http-common";
import localIP from "../localip";
//import i18n from '../i18n'

class DataService {

    async registerUser(data) {
      try {
        const response = await http.post(`/user_api/account/register`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async accountActivate(key) {
      try {
        const response = await http.get(`/user_api/account/mailconfirm/${key}`);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async loginUser(data) {
      try {
        const response = await http.post(`/user_api/account/login`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async loginUserMetamask(data) {
      try {
        const response = await http.post(`/user_api/account/loginMetamask`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async loginCode(data) {
      try {
        const response = await http.post(`/user_api/account/logincode`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async userActivity() {
      try {
        const response = await localIP.get(``);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    
    async loginUserTekrarLink(data){
      try {
        const response = await http.post(`/user_api/account/login/tekrarmail`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async forgotpassword(data) {
      try {
        const response = await http.post(`/user_api/account/forgotpassword`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  
    async newpassword(data) {
      try {
        const response = await http.post(`/user_api/account/newpassword`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
    async getAllWallets() {
      try {
        const response = await http.post(`/api/getAllWallets`);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    async getUserWallets(data) {
      try {
        const response = await http.post(`/api/wallet/userwallet`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }

    async setWalletsEarn(data) {
      try {
        const response = await http.post(`/user_api/wallet/setuserwalletearn`, data);
        return response;
      } catch (error) {
        
        return {data:{error:error.message}}
      }
    }

    async setWalletsColl(data) {
      try {
        const response = await http.post(`/user_api/wallet/setuserwalletcoll`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async getCode(data) {
      try {
        const response = await http.post(`/user_api/wallet/getCode`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async inBorrow(data) {
      try {
        const response = await http.post(`/user_api/wallet/inBorrowCreate`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async outBorrow(data) {
      try {
        const response = await http.post(`/user_api/wallet/outBorrowCreate`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async getAddress(data) {
      try {
        const response = await http.post(`/user_api/wallet/getAddress`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async createWithdraw(data) {
      try {
        const response = await http.post(`/user_api/wallet/withdrawCreate`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async createDeposit(data) {
      try {
        const response = await http.post(`/user_api/wallet/depositCreate`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async apyHistory() {
      try {
        const response = await http.post(`/api/wallet/apyHistory`);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }

    async depositHistory(data) {
      try {
        const response = await http.post(`/api/wallet/depositHistory`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }

    async withdrawHistory(data) {
      try {
        const response = await http.post(`/api/wallet/withdrawHistory`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }

    async swapHistory(data) {
      try {
        const response = await http.post(`/api/wallet/swapHistory`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    
    async logger(data) {
      try {
        const response = await http.post(`/api/other/logger`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }

    async userSessionControl(data) {
      try {
        const response = await http.post(`/api/other/userSessionControl`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    async userActivityControl(data) {
      try {
        const response = await http.post(`/api/other/userActivityControl`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    async veriSifrele(data) {
      try {
        const response = await http.post(`/api/other/veriSifrele`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }

    async swapCrate(data) {
      try {
        const response = await http.post(`/api/swap/swapCrate`, data);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    }
    async addSupport(data) {
      try {
        const response = await http.post(`/api/other/addSupport`, data);
        return response;
      } catch (error) {
        return {data:{error:error.message}}
      }
    }
  }
  
  export default new DataService();

  